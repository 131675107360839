import { useState } from "react";
import "./index.css";
import RightSvg from "assets/right.svg";

const webData = [
  {
    title: "Progressive Web Apps (PWAs)",
    titleLong: "Progressive Web Apps (PWAs)",
    description:
      "Sawatdee crafts cutting-edge Progressive Web Apps tailored for your needs. With a focus on discoverability, responsiveness, and optimal performance, we ensure your product is universally compatible, delivering an immersive user experience across all devices.",
    subTitle: "Sawatdee offers:",
    offers: [
      "Developing state-of-the-art PWAs;",
      "Seamless migration to PWA platforms;",
      "Efficiently integrating PWAs with your existing IT infrastructure.",
    ],
  },
  {
    title: "Website and CMS Development",
    titleLong: "Website & CMS Solutions",
    description:
      "Our commitment is to deliver a website that not only boasts a contemporary aesthetic but is also rich in functionality. We take charge of crafting a custom CMS, ensuring an elegant and user-friendly interface, optimizing for mobile view, swift page loads, seamless social media integration, adherence to privacy standards, SEO optimization, insightful analytics tools, and universal accessibility.",
    subTitle: "Sawatdee offers:",
    offers: [
      "Dedicated portals for team and customer support;",
      "Informative content sites; ",
      "Tailored platforms for your clientele.",
    ],
  },
  {
    title: "UI/UX",
    titleLong: "UI/UX Design Excellence for Web Endeavors",
    description:
      "Join forces with our top-tier in-house UI/UX specialists to attain unparalleled outcomes. Design interfaces that align seamlessly with your users' expectations while authentically showcasing your brand's distinct essence, strengths, and principles.",
    subTitle: "Sawatdee offers:",
    offers: [
      "In-depth user insights and research;",
      "Comprehensive design planning and blueprinting;",
      "Evaluations of existing UI/UX and enhancement recommendations.",
    ],
  },
  {
    title: "Staff Augmentation",
    titleLong: "Enhanced Team Capacity for Web Initiatives",
    description:
      "When you're poised with a plan but short on specific expertise, we can swiftly fill those gaps with our talented professionals, ensuring alignment with your budgetary needs. Leveraging our experience from past Web development ventures, we're adept at maintaining timelines and budgets effectively.",
    subTitle: "With Sawatde’s Staff Augmentation for Web, you'll benefit from:",
    offers: [
      "A diverse range of highly-skilled talents;",
      "Prompt execution under your strategic direction and oversight;",
      "The ability to scale as needed, coupled with transparent processes and cost efficiency.",
    ],
  },
  {
    title: "QA for Web Projects",
    titleLong: "Quality Assurance for Web Endeavors",
    description:
      "At Sawatde, we understand the demands of discerning end-users. Our comprehensive QA framework for Web projects ensures that your product not only adheres to contemporary standards but also offers an efficient, intuitive, and flawless user experience.",
    subTitle: "Our QA offerings include:",
    offers: [
      "Evaluations for functionality, compatibility, and performance;",
      "Assessments of user experience and accessibility;",
      "Checks for localization, security, and system recovery.",
    ],
  },
];

const WebDevelopment = () => {
  const [selectIndex, setSelectIndex] = useState(0);
  const data = webData[selectIndex];
  return (
    <div className="web-dev-container">
      <div className="column-title">Expertise in Web Development</div>
      <div className="web-dev-body">
        <div className="web-left-table">
          {webData.map((e, index) => {
            return (
              <div
                className={`web-left-table-item ${
                  selectIndex === index ? "web-left-table-item-select" : ""
                }`}
                onClick={() => setSelectIndex(index)}
                key={e.title + e.subTitle}
              >
                {e.title}
              </div>
            );
          })}
        </div>
        <div className="web-right-table">
          <div className="web-right-table-title">{data.titleLong}</div>
          <div className="web-right-table-description">{data.description}</div>
          <div className="web-right-sub-table">{data.subTitle}</div>
          {data.offers.map((e) => {
            return (
              <div className="web-right-item" key={e}>
                <img src={RightSvg} alt=""></img>
                <div className="web-right-item-title">{e}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WebDevelopment;
