import Logo1 from "assets/images/web-item-logo01.png";
import Logo2 from "assets/images/web-item-logo02.png";
import Logo3 from "assets/images/web-item-logo03.png";

import "./index.css";

const WebSolution = () => {
  return (
    <div>
      <div className="column-title">Web Solutions Tailored to Your Needs</div>
      <div className="column-description">
        In-depth analytics to monitor website traffic, user engagement, and
        conversion rates, ensuring optimal performance.
      </div>
      <div className="web-body">
        <div className="web-item">
          <img src={Logo1} alt="" className="web-item-logo"></img>
          <div className="web-item-title">Responsive Design</div>
          <div className="web-item-description">
            Ensure your website looks and functions seamlessly on all devices,
            from desktops to smartphones.
          </div>
        </div>
        <div className="web-item">
          <img src={Logo2} alt="" className="web-item-logo"></img>
          <div className="web-item-title">E-Commerce Integration</div>
          <div className="web-item-description">
            Boost your sales with a robust online store, integrated payment
            gateways, and seamless checkout processes.
          </div>
        </div>
        <div className="web-item">
          <img src={Logo3} alt="" className="web-item-logo"></img>
          <div className="web-item-title">Top-tier Web Security</div>
          <div className="web-item-description">
            Trust in our experts to safeguard your website against threats,
            ensuring data integrity and user trust.
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebSolution;
