import { useState } from "react";
import "./index.css";
import RightSvg from "assets/right.svg";

const webData = [
  {
    title: "Modern Web 2.0 - Development Solutions",
    description:
      "Harness the power of Sawatdee's Web 2.0 software development prowess. We craft cutting-edge digital solutions, integrating the latest web technologies, best practices, and unparalleled functionalities.",
    subTitle:
      "Elevate Your Digital Presence with Sawatdee's Web 2.0 Expertise:",
    offers: [
      "Accessible anytime, anywhere;",
      "Intuitive design for optimal user experience;",
      "Streamlined and user-centric navigation;",
      "An efficient strategy to enhance your business outreach.",
    ],
  },
  {
    title: "Web 3.0 Evolution",
    titleLong: "Website & CMS Solutions",
    description:
      "Empower the next wave of your digital solutions with Sawatdee's advanced Web 3.0 development expertise and capabilities. Team up with Sawatdee's Web 3.0 specialists to usher in a transformative web experience for your digital endeavors: ",
    subTitle: "",
    offers: [
      "Prioritize user-centric data control;",
      "Enhanced privacy and fortified security;",
      "Seamless data accessibility and traceability;",
      "Unleash boundless interactions.",
    ],
  },
];

const Expertise = () => {
  const [selectIndex, setSelectIndex] = useState(0);
  const data = webData[selectIndex];
  return (
    <div>
      <div className="column-title">Web 2.0 and Web 3.0 expertise</div>
      <div className="expertise-container max-width">
        <div className="expertise-tabbar">
          <div
            className="expertise-tabbar-button"
            onClick={() => setSelectIndex(0)}
          >
            WEB 2.0 DEVELOPMENT
          </div>
          <div
            className="expertise-tabbar-button"
            onClick={() => setSelectIndex(1)}
          >
            WEB 3.0 DEVELOPMENT
          </div>
          <div
            className="expertise-tabbar-line"
            style={{ left: selectIndex === 0 ? "0px" : "216px" }}
          ></div>
        </div>
        <div className="expertise-body">
          <div className="web-right-table-title">{data.title}</div>
          <div className="web-right-table-description">{data.description}</div>
          <div className="web-right-sub-table">{data.subTitle}</div>
          {data.offers.map((e) => {
            return (
              <div className="web-right-item" key={e}>
                <img src={RightSvg} alt=""></img>
                <div className="web-right-item-title">{e}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Expertise;
