import React, { HtmlHTMLAttributes, useRef } from "react";
import "./App.css";
import Header from "components/Header";
import Banner from "components/Banner";
import WebSolution from "components/WebSolution";
import ShowCasing from "components/ShowCasing";
import WebDevelopment from "components/WebDevelopment";
import Expertise from "components/Expertise";
// import PriceBoard from "components/PriceBoard";
import Schedule from "components/Schedule";
import Footer from "components/Footer";

function App() {
  const ref = useRef<HTMLDivElement>(null);
  const onClick = () => {
    if (ref.current) {
      window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" });
    }
  };
  return (
    <div className="App">
      <div className="App-container">
        <div className="Page-Container">
          <Header onClick={onClick}></Header>
          <Banner onClick={onClick}></Banner>
          <div className="Page-body">
            <WebSolution></WebSolution>
            <ShowCasing></ShowCasing>
            <WebDevelopment></WebDevelopment>
            <Expertise></Expertise>
            {/* <PriceBoard></PriceBoard> */}
            <div ref={ref}>
              <Schedule></Schedule>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}

export default App;
