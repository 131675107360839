import "./index.css";
import Logo from "assets/images/logo_transparent.png";

const Header: React.FC<{ onClick: () => void }> = (props) => {
  return (
    <div className="header-container max-width">
      <img src={Logo} alt="" className="app-logo"></img>
      <div className="button button-text" onClick={props.onClick}>
        Get a Quote
      </div>
    </div>
  );
};

export default Header;
