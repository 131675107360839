import { Grid, Input, Snackbar } from "@mui/material";
import "./index.css";
import { useState } from "react";
// import emailjs from "@emailjs/browser";

const Schedule = () => {
  const sendRequest = () => {
    // e.preventDefault();
    // emailjs
    //   .sendForm(
    //     "service_dwjl9ud",
    //     "template_ir1d7bi",
    //     `{ email: "951472397@qq.com" }`,
    //     "imyheL0d8627v1y3k"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    // setOpen(true);
  };

  const [open, setOpen] = useState(false);

  return (
    <div className="schedule-container max-width">
      <div>
        <div className="schedule-title">Schedule a free IT consultation</div>
        <div className="schedule-prompt">What happens next?</div>
        <div className="schedule-question-container">
          <div className="point-container"></div>
          <div className="schedule-question-list">
            <div className="schedule-question-title">
              An expert contacts you after having analyzed your requirements;
            </div>
            <div className="schedule-question-title">
              If needed, we sign an NDA to ensure the highest privacy level;
            </div>
            <div className="schedule-question-title">
              We submit a comprehensive project proposal with estimates,
              timelines, CVs, etc.
            </div>
          </div>
        </div>
      </div>
      <div className="from-table">
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ paddingBottom: 24 }}>
            <Input placeholder="Enter your industry*" fullWidth></Input>
          </Grid>
          <Grid item xs={6} style={{ paddingBottom: 24 }}>
            <Input placeholder="Name" fullWidth></Input>
          </Grid>
          <Grid item xs={6} style={{ paddingBottom: 24 }}>
            <Input placeholder="Corporate E-mail*" fullWidth></Input>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 24 }}>
            <Input placeholder="Phone*" fullWidth></Input>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 24 }}>
            <Input
              placeholder="Please describe your project requirements*"
              fullWidth
            ></Input>
          </Grid>
          <Grid item xs={4}>
            <div className="button button-text" onClick={sendRequest}>
              Send request
            </div>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(fail)}
        message="Note archived"
      />
    </div>
  );
};

export default Schedule;
