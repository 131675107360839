import "./index.css";
import Logo from "assets/images/logo_transparent.png";

const Footer = () => {
  return (
    <div className="footer-container max-width">
      <img src={Logo} alt="" className="footer-logo"></img>
      <div className="address-text">
        Our office: Ashtown Lodge, River Road, Dublin 15, DUBLIN, Ireland
      </div>
      <div className="button-container">
        <div className="text-button">Terms of Service</div>
        <div className="text-button">Privacy Policy</div>
      </div>
    </div>
  );
};

export default Footer;
