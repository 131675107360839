import SwissSvg from "assets/swiss.svg";
import Banner01 from "assets/images/showcas-banner-01.png";
import Banner02 from "assets/images/showcas-banner-02.png";
import Banner03 from "assets/images/showcas-banner-03.png";

import "./index.css";

const ShowCasing = () => {
  return (
    <div>
      <div className="column-title">Showcasing Our Finest Works</div>
      <div className="column-description">
        Get inspired by our diverse collection of projects, each telling a
        unique story and demonstrating our commitment to excellence.
      </div>
      <div className="show-body">
        <div className="show-column-row">
          <div className="show-item">
            <img src={Banner03} alt="" className="show-item-logo"></img>
            <img src={SwissSvg} alt="" className="show-item-min-logo"></img>
            <div className="show-item-title">
              A Website for a Humanitarian Organization
            </div>
          </div>
          <div className="show-item">
            <img src={Banner01} alt="" className="show-item-logo"></img>
            <div className="show-item-min-logo"></div>
            <div className="show-item-title">
              A FinTech Portal to Compare Utility Payment Rates
            </div>
          </div>
        </div>
        <div className="show-column-row">
          <div className="show-item-description">
            An IoT-Based Health Tracking Solution for Medical Professionals
          </div>
          <div className="show-item">
            <img src={Banner02} alt="" className="show-item-max-logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowCasing;
