import { Button } from "@mui/material";
import "./index.css";
import Logo from "assets/images/banner01.png";
import React from "react";

const Banner: React.FC<{ onClick: () => void }> = (props) => {
  return (
    <div className="banner-container max-width">
      <div className="banner-left-container">
        <div className="banner-title">Building Tomorrow's Websites Today</div>
        <div className="banner-prompt">
          Empowered by cutting-edge technology and a team of experts, Sawatdee
          Ireland Limited delivers state-of-the-art web solutions.
        </div>
        <Button onClick={props.onClick}>
          <div className="button button-text">Start Your Project</div>
        </Button>
      </div>
      <img src={Logo} alt="" className="logo"></img>
    </div>
  );
};

export default Banner;
